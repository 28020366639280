import React from 'react'
import { InviteBanner, FerryBanner } from '../../components/banners'
import { Swiper, Button, Wrapper, H3, H4, Link } from '@raysync/common/components'
import Layout from '../../components/layout'
import {
  Title,
  Desc,
  ChallengeSection,
  ChallengeList,
  ChallengeItem,
  SolutionSection,
  FeatureSection,
  FeatureList,
  FeatureItem,
  ValueSection,
  ValueList,
  ValueItem,
  ExampleSection,
  ExampleList,
  FerryWrap,
} from './atoms'

import Feature1 from '@raysync/common/components/svg/products/ferry/icon1'
import Feature2 from '@raysync/common/components/svg/products/ferry/icon2'
import Feature3 from '@raysync/common/components/svg/products/ferry/icon3'
import Feature4 from '@raysync/common/components/svg/products/ferry/icon4'
import Feature5 from '@raysync/common/components/svg/products/ferry/icon5'
import Feature6 from '@raysync/common/components/svg/products/ferry/icon6'
import Value1 from '@raysync/common/components/svg/products/ferry/icon7'
import Value2 from '@raysync/common/components/svg/products/ferry/icon8'
import Value3 from '@raysync/common/components/svg/products/ferry/icon9'
import Value4 from '@raysync/common/components/svg/products/ferry/icon10'
import ChallengeIcon1 from '@raysync/common/components/svg/products/ferry/challenge1'
import ChallengeIcon2 from '@raysync/common/components/svg/products/ferry/challenge2'
import ChallengeIcon3 from '@raysync/common/components/svg/products/ferry/challenge3'
import ChallengeIcon4 from '@raysync/common/components/svg/products/ferry/challenge4'
import Arch from '@raysync/common/components/svg/products/ferry/arch'
import Example1 from './images/example1.png'

class RaysyncFerry extends React.PureComponent {
  state = {
    challengeList: [
      {
        title: '安全风险高',
        desc: '跨网文件交换涉及不同网络之间的数据传输，缺乏严格的安全审计和权限控制机制，导致敏感信息泄露和滥用',
        svg: <ChallengeIcon1 />,
      },
      {
        title: '管理复杂度高',
        desc: '企业网络结构复杂，涉及多个部门和多个网络区域。现有方案难以实现对文件传输行为的统一管理和控制',
        svg: <ChallengeIcon2 />,
        isReverse: true,
      },
      {
        title: '效率低下',
        desc: '传统的跨网文件交换方式往往依赖于人工手动管理，流程繁琐，不仅效率低下，而且容易出错',
        svg: <ChallengeIcon3 />,
      },
      {
        title: '合规性挑战',
        desc:
          '数据安全相关法律法规要求企业积极应对，采取有效措施，确保跨网文件交换的合规性，避免因违规操作而带来的法律风险和损失',
        svg: <ChallengeIcon4 />,
        isReverse: true,
      },
    ],
    featureList: [
      {
        title: '灵活的外发文件审批',
        desc: '系统具备灵活的审批体系，支持会签、或签等，实现文件审批流转提醒，支持与第三方审批系统集成',
        svg: <Feature1 />,
      },
      {
        title: '企业级安全管控',
        desc:
          '根据不同安全要求，对数据包裹的内容、提取进行多维度的安全保护，支持文件类型、大小限制;病毒查杀;敏感词检测;双因素认证;与第三方DLP系统集成',
        svg: <Feature2 />,
      },
      {
        title: '企业级集成能力',
        desc:
          '开放SDK、HTTPAPI集成支持。支持企业ad域、企业邮箱、Linux系统、企微等用户系统集成。支持与企业OA系统、BPM系统进行流程集成',
        svg: <Feature3 />,
      },
      {
        title: '高速安全传输',
        desc:
          '自研raysync协议传输速度比FTP/HTTP快100倍，海量小文件可支持上万并发，支持TLS加密传输，断点续传、错误重传、多重文件校验，保障文件高速安全传输',
        svg: <Feature4 />,
      },
      {
        title: '多样的文件交换策略',
        desc:
          '针对不同的业务场景灵活部署传输节点，基于传输节点，制定相应的文件交换策略，实现不同网络下统一的内容管理，实现一对一，或者一对多的文件交换',
        svg: <Feature5 />,
      },
      {
        title: '全流程可视审计',
        desc:
          '提供日志审计和查询。文件的发送者及相关管理员可以对文件所处位置与当前状态进行持续跟踪，大大提升数据交换全过程的可视性，提高业务运转效率',
        svg: <Feature6 />,
      },
    ],
    valueList: [
      {
        title: '规避合规风险',
        desc: '提供完整的审计和追溯功能，记录每一次文件交换的详细信息，为企业的合规性检查提供有力支持',
        svg: <Value1 />,
      },
      {
        title: '保障数据安全',
        desc:
          '采用先进的加密技术、多种检测机制和访问控制机制，确保文件在跨网传输过程中的安全性，降低数据泄露风险，保护企业的核心资产和业务信息',
        svg: <Value2 />,
      },
      {
        title: '提升业务效率',
        desc: '通过高性能的传输协议和安全的文件流转管理，大幅减少文件传输所需的时间，有助于加快业务决策和文件流转效率',
        svg: <Value3 />,
      },
      {
        title: '优化资源管理',
        desc:
          '实现对文件交换过程的统一管理和控制，包括用户管理、权限设置、文件存储和备份等。这有助于企业优化资源配置，减少不必要的浪费，同时提高资源的使用效率',
        svg: <Value4 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { challengeList, featureList, valueList } = this.state
    return (
      <Layout pageType='ferry' location={location}>
        <FerryWrap>
          <Swiper>
            <FerryBanner />
          </Swiper>
          <ChallengeSection>
            <Wrapper>
              <Title>企业文件交换面临的挑战</Title>
              <Desc>
                企业往往拥有多个网络，如办公网、生产网、研发网等，这些网络之间需要进行文件传输，以满足不同部门之间的协同工作需求，传统的文件传输方式，如U盘或即时通讯工具虽然能满足企业基础的跨域文件交换需求，但在实际应用中，企业仍存在管理及建设痛点
              </Desc>
              <ChallengeList>
                {challengeList.map((l, i) => (
                  <ChallengeItem key={i}>
                    {l.svg}
                    <h4>{l.title}</h4>
                    <p>{l.desc}</p>
                  </ChallengeItem>
                ))}
              </ChallengeList>
            </Wrapper>
          </ChallengeSection>
          <SolutionSection>
            <Wrapper>
              <Title>跨网文件交换解决方案</Title>
              <Desc>
                镭速文件交换系统支持多种网络隔离架构下的跨网文件交换，支持多个隔离网之间的安全交换，同时支持审批，追溯原始文件，是解决网络隔离条件下，数据安全收发、摆渡、共享的理想解决方案。
              </Desc>
              <Arch />
            </Wrapper>
          </SolutionSection>
          <FeatureSection>
            <Wrapper>
              <Title>产品特点</Title>
              <Desc>
                企业往往拥有多个网络，如办公网、生产网、研发网等，这些网络之间需要进行文件传输，以满足不同部门之间的协同工作需求，传统的文件传输方式，如U盘或即时通讯工具虽然能满足企业基础的跨域文件交换需求，但在实际应用中，企业仍存在管理及建设痛点
              </Desc>
              <FeatureList>
                {featureList.map((l, i) => (
                  <FeatureItem key={i}>
                    {l.svg}
                    <h4>{l.title}</h4>
                    <p>{l.desc}</p>
                  </FeatureItem>
                ))}
              </FeatureList>
            </Wrapper>
          </FeatureSection>
          <ValueSection>
            <Wrapper>
              <Title>方案价值</Title>
              <ValueList>
                {valueList.map((l, i) => (
                  <ValueItem key={i}>
                    {l.svg}
                    <div>
                      <h4>{l.title}</h4>
                      <p>{l.desc}</p>
                    </div>
                  </ValueItem>
                ))}
              </ValueList>
            </Wrapper>
          </ValueSection>
          <ExampleSection>
            <Wrapper>
              <Title>客户案例</Title>
              <ExampleList>
                <img src={Example1} alt='bank image' />
                <div>
                  <h3>某金融机构</h3>
                  <h4>客户痛点：</h4>
                  <p>1.业务数据增量剧增，存储压力日益变大；</p>
                  <p>2.由于异构业务数据众多，数据统一存储和管理困难；</p>
                  <p>3.总行下发或搜集相关数据，由于业务分布广、距离远，分行与总行之间的数据传输效率极低；</p>
                  <p>4.VPN虚拟线路和专线带宽的利用率不高，传输过程往往因误传或断网造成中断，需重新上传；</p>
                  <p>5.没有严谨的数据校验机制，无法保障数据的完整性、可用性；</p>
                  <Link to={'/example/example-detail-1109.html'}>
                    <Button size='large'>查看具体解决方案</Button>
                  </Link>
                </div>
              </ExampleList>
            </Wrapper>
          </ExampleSection>

          <Swiper>
            <InviteBanner type='ferry' />
          </Swiper>
        </FerryWrap>
      </Layout>
    )
  }
}

export default RaysyncFerry
